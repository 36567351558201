<template>
  <b-container class="side-bar-area aside-bottom-padding w-100">
    <b-row class="w-100">
      <b-row
        align-h="between"
        class="w-100"
      >
        <b-col style="padding: 0px !important;">
          <service-unit-select
            v-show="!fullScreenWorkArea"
            class="mx-1 "
            @service-unit="setServiceUnit"
          />
        </b-col>
        <TraverseQueue style="margin-right: 2px; margin-left: 5px;" />
      </b-row>
      <b-col
        v-show="!fullScreenWorkArea"
        cols="12"
        style="padding-left: 0px !important; "
        class="search-input no-border"
      >
        <search-input
          @showDatePicker="showPicker"
          @search="handleSearch"
        />
      </b-col>
      <b-col
        v-show="showDatePicker"
        cols="12"
        class="search-input no-border"
      >
        <b-form-datepicker
          v-model="selectedDate"
          style="height:40px;"
          size="sm"
          :show-decade-nav="true"
          today-button
          reset-button
          close-button
          locale="en"
          @input="pickingDate"
        />
      </b-col>
      <b-col
        v-show="inSearchMode && !fullScreenWorkArea"
        cols="12"
        class="no-results"
      >
        <span> {{ searchResultText }}</span>
        <b-button
          v-if="apiList.length === 0"
          size="sm"
          @click="clearSearch"
        >clear
        </b-button>
      </b-col>
      <b-col
        cols="12"
        class="top-margin-space"
        :class="fullScreenWorkArea ? closed : nothing"
      >
        <patient-list
          :patient-list="apiList"
          :rows="total"
          :full-screen-work-area="fullScreenWorkArea"
          @selected-patient="patientSelected"
          @current-page="emitPage"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TraverseQueue from '@/views/erp/clinical/top-header/components/traverse-queue/TraverseQueue.vue'
import PatientList from './PatientList.vue'
import SearchInput from './SearchInput.vue'
import ServiceUnitSelect from './ServiceUnitSelect.vue'

export default {
  name: 'SideBar',
  components: {
    PatientList,
    SearchInput,
    ServiceUnitSelect,
    TraverseQueue,
  },
  props: {
    fullScreenWorkArea: {
      type: Boolean,
      default: false,
    },
    apiList: {
      type: Array,
      default: [],
    },
    searchResultText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      placeholder: '  Search patient...',
      total: 50,
      // apiList: [],
      inSearchMode: false,
      currentServiceUnit: null,
      currentSelectedPatient: null,
      closed: 'closed',
      nothing: 'nothing',
      filterdList: [],
      trackApiCall: 0,
      showDatePicker: 0,
      selectedDate: new Date().toISOString().slice(0, 10),
    }
  },
  computed: {
    apiList() {
      return this.$store.getters['patient/getPatients']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint']
    },
    servicePoints() {
      return this.$store.getters['servicePoints/getServicePoints']
    },
  },
  watch: {
    selectedServiceUnit(unit) {
      this.$store.dispatch('patient/setSelectedPatient', {})
      // this.$store.dispatch("servicePoint/setSelectedServicePoint", {});
      if (unit && unit.service_unit) {
        this.getServicePoints(unit)
      }
      if (unit.service_unit_details.allow_appointments == 0) {
        const payload = {
          serviceUnit: unit.service_unit, servicePoint: '', search: '', work: 'patient/core/sidebar1',
        }
        this.$store.dispatch('patient/fetchPatients', payload)
      }
    },
    selectedServicePoint(point, oldPoint) {
      console.log('PREVIOUS', oldPoint.name)
      console.log('NEW', point.name)
      // if (point !== undefined && (point.name != oldPoint.name)) {
      if (point !== undefined) {
        const payload = {
          serviceUnit: this.selectedServiceUnit.service_unit, servicePoint: point.name, search: '', work: 'patient/core/sidebar 2',
        }
        this.$store.dispatch('patient/fetchPatients', payload)
      }
      // this.$store.dispatch("servicePoint/setSelectedServicePoint", point);
    },
  },
  methods: {
    showPicker() {
      if (this.showDatePicker == 0) {
        this.showDatePicker = 1
      } else {
        this.showDatePicker = 0
      }
    },
    pickingDate() {
      this.$store.dispatch('servicePoint/setQueueDate', this.selectedDate)
    },
    getServicePoints(unit) {
      this.$store.dispatch('servicePoint/fetchMyServicePoints', unit)
    },
    toggleFullView() {
      this.fullScreenWorkArea = !this.fullScreenWorkArea
      this.$emit('full-screen-work-area', this.fullScreenWorkArea)
    },
    contextSetContextItem(payload) {
      this.$store.dispatch('context/setContextItem', payload)
    },
    handleSearch(search) {
      this.$emit('search', search)
      console.log('EITTING PATIENT SEARCH.................')
    },

    patientSelected(patient) {
      console.log('sidebar')
      this.currentSelectedPatient = patient
      this.$emit('selected-patient', patient)
      this.contextSetContextItem({ key: 'patient_name', value: patient.patient })
    },
    emitPage(page) {
      this.$emit('current-page', page)
      // get next or previous page
    },
    getPatientList(serviceUnitName, search) {
      // const payload = { serviceUnit: serviceUnitName, search: search }
    },
    setServiceUnit(servicUnit) {
      this.selectedServiceUnit = servicUnit
      this.$emit('selected-service-unit', servicUnit)
    },
    clearSearch() {
      this.inSearchMode = false
      this.$emit('clearSearch')
    },
  },
}
</script>

<style scoped>
.no-sides {
    margin-left: 0px;
    margin-right: 8px;
}

.side-bar-area {
    background: rgba(0, 0, 0, 0.03);
    width: 100% !important;
    padding-top: 20px;
    height: 85vh;

    overflow-y: hidden;
    overflow-x: hidden;

    padding-left:4px !important;
    padding-right: 4px !important;
}

.name-header {
    background: #687178;
    color: white;
    padding: 14px;
    border-radius: 0px 16px 16px 0px;
    width: 95%;
    font-weight: 800;
}

.search-input {
    margin-top: 5%;

}

.no-results {
    margin-top: 5%;
    padding-left: 7%;
}

.rounded {
    border-radius: 12px;
}

.top-margin-space {
    margin-top: 16px;
    padding-bottom: 16px;
}

.no-border {
    border: 0px solid black !important;
}

.form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;
    font-weight: 400;
    line-height: 1.45;
    font-size: 13px;
    color: #6e6b7b;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-custom {
    height: 36px;
}

.menu-options {
    margin-left: 0px;
    font-size: 18px;
    margin-top: 12px;
    width: 100%;
}

.closed {
    padding-right: 0px !important;
    padding-left: 6px !important;
}
</style>
