<template>
  <div>
    <b-modal
      id="modal-scrollable"
      no-close-on-backdrop
      size="xl"
      scrollable
      title="Scrollable Content"
      hide-footer
      @hide="onModalClose"
    >
      <template #modal-title />
      <div>
        <import-global-patient
          :quick_registration="1"
          @sendRegisteredPatient="handledRegisteredPatient"
        />
      </div>
    </b-modal>

    <b-button
      style="border:0px !important; padding: 0px !important; margin: 0px !important;"
      size="sm"
      class="mx-1"
      variant="outline-danger"
      @click="existingPatient()"
    >
      <feather-icon
        icon="UserPlusIcon"
        size="14"
      /> Add
    </b-button>
    <!-- <b-button
      class="pull-right mr-2 ml-2"
      @click="existingPatient()"
      variant="danger btn-sm"
    >
     <i class="fa fa-bolt" aria-hidden="true"></i> Quick Registration
    </b-button> -->
    <!-- <p @click="existingPatient"> .</p> -->
  </div>
</template>

<script>
import ImportGlobalPatient from '@/views/erp/records/patient/PatientImportTool.vue'
import { quickNewPatientRegistrationAndQueueing, quickPatientQueueing } from './service'

export default {
  name: 'QuickRegisterPatient',
  components: {
    ImportGlobalPatient,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
    service_unit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      medical_dept: '',
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
  },
  watch: {

  },
  created() {

  },
  methods: {
    existingPatient(patientNumber = '') {
      const parent = this
      const dialog = new frappe.ui.Dialog({
        title: 'Quick Register/Queue Emergency Patient ',
        fields: [
          {
            fieldtype: 'Button',
            fieldname: 'category',
            options: 'Patient',
            label: 'Register New Patient',
          },
          {
            label: '',
            fieldname: 'loading',
            fieldtype: 'Read Only',
            default: 'Use "Register" button above if the patient has never been registered in the facility',
          },
          {
            fieldtype: 'Check',
            fieldname: 'emergency',
            options: 'emergency',
            label: 'Is an Emergency Patient',
          },
          {
            fieldtype: 'Link',
            fieldname: 'patient',
            options: 'Patient',
            default: patientNumber,
            label: 'Select existing patient to create appointment for',
            reqd: 1,
          },
          {
            fieldtype: 'Link',
            fieldname: 'service_unit',
            label: 'Choose Service Unit(type to search)',
            default: '',
            options: 'Healthcare Service Unit',
            reqd: 1,
          },
          {
            fieldtype: 'Select',
            fieldname: 'queue_status',
            label: 'Choose Queue Status',
            default: 'Awaiting Vitals',
            options: ['Awaiting Vitals', 'Awaiting Consultation', 'Awaiting Investigation'],
            reqd: 1,
          },

        ],
        primary_action_label: ('Submit'),
        primary_action: values => {
          // dialog.get_primary_btn().attr("disabled", true);
          dialog.set_values({
            loading: `<div class="loader" style="border: 16px solid white;border-radius: 50%;border-top: 16px solid green;width: 30px;height: 30px;-webkit-animation: spin 2s linear infinite;animation: spin 2s linear infinite;"></div>
                      <p>Creating Patient Appointment for ${values.patient}, please wait....</p>`,
          })

          quickPatientQueueing(values.patient, values.service_unit, values.emergency, values.queue_status).then(res => {
            dialog.hide()
            // this.msgprint({
            //   title: ("Notification"),
            //   indicator: "green",
            //   message: ("Patient inserted in the queue"),
            // });
          })
        },
      })
      dialog.fields_dict.category.input.onclick = function () {
	            // parent.newPatient()
        parent.$bvModal.show('modal-scrollable')
        dialog.hide()
      }
      dialog.show()
      dialog.$wrapper.find('button[data-fieldtype="Button"]').addClass('btn-primary')
      dialog.$wrapper.find('.modal-content').css('width', '1000px')
      dialog.$wrapper.find('.modal-content').css('margin-left', '-200px')
      dialog.$wrapper.find('.modal-content').css('backdrop', 'static')
      dialog.$wrapper.find('.modal-content').css('keyboard', 'false')
    },
    newPatient() {
      // const parent = this
      const dialog = new frappe.ui.Dialog({
        title: 'Quick Register Emergency Patient ',
        fields: [
          {
            fieldtype: 'Check',
            fieldname: 'emergency',
            options: 'emergency',
            label: 'Is an Emergency Patient',
          },
          {
            fieldtype: 'Data',
            fieldname: 'first_name',
            label: 'First Name',
            reqd: 1,
          },
          {
            fieldtype: 'Data',
            fieldname: 'last_name',
            label: 'Last Name',
            reqd: 1,
          },
          {
            fieldtype: 'Select',
            fieldname: 'gender',
            label: 'Gender',
            options: ['Male', 'Female'],
            reqd: 1,
          },
          {
            fieldtype: 'Date',
            fieldname: 'dob',
            label: 'Date Of Birth',
            reqd: 1,
          },
          {
            fieldtype: 'Link',
            fieldname: 'service_unit',
            label: 'Choose Service Unit(type to search)',
            default: '',
            options: 'Healthcare Service Unit',
            reqd: 1,
          },
          {
            fieldtype: 'Select',
            fieldname: 'queue_status',
            label: 'Choose Queue Status',
            default: 'Awaiting Vitals',
            options: ['Awaiting Vitals', 'Awaiting Consultation', 'Awaiting Investigation'],
            reqd: 1,
          },
        ],
        primary_action_label: ('Submit'),
        primary_action: values => {
          dialog.get_primary_btn().attr('disabled', true)
          dialog.set_values({
            first_name: `Processing request for ${values.first_name} ${values.last_name}, please wait....`,
          })
          quickNewPatientRegistrationAndQueueing(values.first_name, values.last_name, values.gender, values.dob, values.service_unit, values.emergency, values.queue_status).then(res => {
            dialog.hide()
            //     this.msgprint({
            //   title: ("Notification"),
            //   indicator: "green",
            //   message: ("Patient registered and inserted in the queue"),
            // });
          })
        },
      })
      dialog.show()
    },
    handledRegisteredPatient(patientNumber) {
      this.existingPatient(patientNumber)
      this.$bvModal.hide('modal-scrollable')
    },

  },

}
</script>

<style>
.vl {
  border-left: 1px solid grey;
  height: 70px;
  visibility:hidden;
}
</style>
