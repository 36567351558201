<template>
  <b-container class="search-bar-container">
    <b-row
      class="w-100"
      onsubmit="return false;"
    >
      <b-col
        class="search-bar text-muted no-sides"
        style="padding-right: 0px !important; margin-right: 5px !important;"
      >
        <b-form-input
          id="navbar-search"
          v-model="searchTerm"
          class="form-control rounded w-100"
          style="height: 30px; margin: 0px !important;"
          :placeholder="placeholder"
          size="lg"
          aria-haspopup="true"
          @focus="placeholder = 'Search patient..'"
          @blur="placeholder = 'Search patient...'"
        />
      </b-col>

      <b-button
        v-b-tooltip.hover
        size="sm"
        title="Search for patients"
        style="margin-right: 2px; height: 30px;"
        variant="secondary"
        @click="searchClicked"
      >
        <i
          class="fa fa-search"
          aria-hidden="true"
        />
      </b-button>
      <b-button
        v-if="selectedServiceUnit.service_unit_details.allow_appointments == 1"
        style="height: 30px;"
        size="sm"
        variant="outline-secondary"
        @click="showDatePicker"
      >
        <i
          class="fa fa-calendar"
          aria-hidden="true"
        />
      </b-button>

    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      searchTerm: '',
      placeholder: '      Search...',
      statusValue: 0,
      statusValueObject: {
        '': 0, 'Awaiting Vitals': 1, 'Awaiting Consultation': 2, 'Awaiting Investigation': 3, 'Visit Completed': 4,
      },
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint'] || (this.servicePoints && this.servicePoints.length && this.servicePoints[0])
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    selectedServiceUnit() {
      this.placeholder = '    Search...'
      this.statusValue = 0
    },
    selectedServicePoint() {
      this.placeholder = '    Search...'
      this.statusValue = 0
    },
  },
  methods: {
    showDatePicker() {
      this.$emit('showDatePicker')
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value)
    },
    searchClicked() {
      const search = this.searchTerm
      this.searchTerm = ''
      this.$emit('search', search)
    },
    browseDownQueueStatus() {
      if (this.statusValue > 0) {
        this.statusValue -= 1
        if (this.statusValue == 0) {
          const payload = {
            serviceUnit: this.selectedServiceUnit.service_unit, servicePoint: this.selectedServicePoint.name, search: '', work: 'patient/component/searchinput',
          }
          this.$store.dispatch('patient/fetchPatients', payload)
          this.placeholder = '    Search...'
        } else {
          const val = this.getKeyByValue(this.statusValueObject, this.statusValue)
          this.$emit('search', val)
          this.placeholder = `     ${val} List`
        }
      }
    },
    browseUpQueueStatus() {
      if (this.statusValue < 4) {
        this.statusValue += 1
        const val = this.getKeyByValue(this.statusValueObject, this.statusValue)
        this.$emit('search', val)
        this.placeholder = `     ${val} List`
      }
    },
  },
}
</script>

<style scoped>
.search-bar-container {
    height: 20px !important;
}

.no-sides {
    margin-left: 0px;
    margin-right: 0px;
}

.side-bar-area {
    background: white;
    width: 100% !important;
    padding-top: 10px;
    min-height: 600px;
}

.name-header {
    background: #687178;
    color: white;
    padding: 14px;
    border-radius: 0px 16px 16px 0px;
    width: 95%;
    font-weight: 800;
}

.rounded {
    border-radius: 12px;
}

.top-margin-space {
    margin-top: 16px;
    padding-bottom: 16px;
}

.form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;
    height: 36px;
    font-size: 13px;
    color: #6e6b7b;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-custom {
    height: 36px;
}

.search-bar {
    flex: 1;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-left: 0px !important;
    margin-right: 0px !important;

    background: white;
    max-width: 3000px;
}
</style>
