<template>
  <b-container
    class="ward-selection w-100"
    style="margin: 0px !important;"
  >
    <b-row class="no-background w-100">
      <b-dropdown
        :text="selectedServiceUnit.service_unit || 'Select a service unit'"
        variant="secondary"
        split
        split-variant="outline-secondary"
        class="fill-width custom-menu-bg"
        size="sm"
      >
        <b-dropdown-item
          v-for="unit in serviceUnits"
          :key="unit.name"
          @click="setServiceUnit(unit)"
        >{{ unit.service_unit }}</b-dropdown-item>
      </b-dropdown>
    </b-row>
  </b-container>
</template>
<script>

export default {
  name: 'ServiceUnitSelect',

  components: {},
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit'] || (this.serviceUnits.length && this.serviceUnits[0])
    },
    serviceUnits() {
      this.$emit('service-unit', this.$store.getters['serviceUnit/getServiceUnits'][0].service_unit)
      return this.$store.getters['serviceUnit/getServiceUnits']
    },
    servicePoints() {
      return this.$store.getters['servicePoints/getServicePoints']
    },
  },
  watch: {},
  created() {
    this.getServiceUnits()
  },
  methods: {
    setServiceUnit(unit) {
      this.$store.dispatch('serviceUnit/setSelectedServiceUnit', unit)
      this.$emit('service-unit', unit.service_unit)
      this.getServicePoints(unit)
    },
    getServiceUnits() {
      this.$store.dispatch('serviceUnit/fetchMyServiceUnits')
    },
    getServicePoints(unit) {
      this.$store.dispatch('servicePoint/fetchMyServicePoints', unit)
    },
  },
}
</script>
<style>
.fill-width {
  width: 100% !important;
}
.firt-text {
  font-size: large;
}
.side-bar-area {
  background: green;
}
.cust-nav {
  box-shadow: 0px 0px !important;
  padding: 0px !important;
}

.ward-selection {
  background: rgba(76, 175, 80, 0);
  padding: auto;
}

.unit-title {
  font-weight: 700;
  color: dimgray;
  margin-bottom: 0px !important;
}

.no-background {
  background: rgba(76, 175, 80, 0);
}
.custom-menu-bg {
  background: white;
  border-radius: 7px;
  border: 0px;
}
</style>
