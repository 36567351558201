import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatientsAwaiting = (service_unit, status, passed_date = '') => api({
  method: 'clinical.api.queue_management.queue_logistics.get_number_of_patients_awaiting',
  args: {
    service_unit,
    status,
    passed_date,
  },
})
