import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const quickNewPatientRegistrationAndQueueing = (first_name, last_name, gender, dob, service_unit, emergency, queue_status) => api({
  method: 'clinical.api.appointment.appointment.quick_new_patient_registration_and_queueing',
  args: {
    first_name,
    last_name,
    gender,
    dob,
    service_unit,
    emergency,
    queue_status,
  },
})

export const quickPatientQueueing = (patient, service_unit, emergency, queue_status) => api({
  method: 'clinical.api.appointment.appointment.quick_patient_queueing',
  args: {
    patient,
    service_unit,
    emergency,
    queue_status,
  },
})
