<template>
  <wrapper>
    <b-overlay style="width:100% !important" :show="isLoading" :opacity="0.7" rounded="sm">
      <b-container class="mb-3">
        <b-row class="">

          <b-col />
          <b-button class="mt-1 mx-1 mb-1" variant="dark" size="sm" @click="cancel">
            <span>Reset</span>
          </b-button>
        </b-row>

        <b-row v-if="!checkingComplete">
          <h5 class="mx-4 ">
            SELECT PATIENT IDENTIFIER TYPE
          </h5>
          <b-form-select v-model="patientIdentifierType" class="mx-4 mt-1" :options="options" />
        </b-row>

        <b-row v-if="patientIdentifierType != null && !checkingComplete">
          <h5 class="mx-4 mt-1">
            ENTER PATIENT IDENTIFIER NUMBER
          </h5>
        </b-row>

        <b-row v-if="patientIdentifierType != null && !checkingComplete">
          <b-form-input v-model="patientIdentifierNumber" class="mx-4 mt-1" style="width:100%"
            placeholder="Enter identification number" />
        </b-row>

        <b-row
          v-if="patientIdentifierType != null && patientIdentifierNumber != null && patientIdentifierNumber.length > 3 && !checkingComplete">
          <b-button size="sm" class="mx-4 mt-1 mb-1" variant="primary" @click="searchPatient">Search
            Patient </b-button>
        </b-row>

        <b-row v-if="checkingComplete && !registerNew" style="width:100% !important; ">
          <fill-form :pre-populate="searchResults" :register-new="registerNew" class=" mx-2" :form-name="formName"
            :is-readonly="registerNew" @cancel="cancel" />
        </b-row>
        <b-row v-if="checkingComplete && registerNew" class="w-100">
          <frm class="w-100" :form-name="formName" style="border:'1px solid grey '" :is-readonly="registerNew"
            @callback="callback" />
        </b-row>

      </b-container>
    </b-overlay>
  </wrapper>
</template>

<script>
import FillForm from '@/views/components/formbuilder/FillForm.vue'
import Frm from './CreateNewPatient.vue'
import SearchPatient from './SearchPatientGlobal.vue'

export default {
  name: 'ImportPatient',
  components: { FillForm, SearchPatient, Frm },
  props: {
    quick_registration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formName: 'Registration Form',
      isLoading: false,
      visible: false,
      prePopulate: null,
      selectedItem: [],
      searchResults: null,
      tabIndex: 0,
      patientIdentifierType: null,
      registerNew: false,
      loading: false,
      checkingComplete: false,
      patientIdentifierNumber: null,
      options: [
        { value: 'national-id', text: 'National Id' },
        { value: 'birth-certificate', text: 'Birth Certificate' },
        { value: 'passport', text: 'Passport' },

      ],
    }
  }, mounted(){
     this.setTitle("Register Patient")
  },
  methods: {
    callback(value) {
      const id = value.document.name
      if (this.quick_registration == 1) {
        this.$emit('sendRegisteredPatient', id)
      } else {
        this.$router.push({ name: 'patient-view-actions', params: { id } })
      }
    },
    searchPatient() {
      this.prePopulate = {}
      this.isLoading = true
      const context = this
      const args = { id_number: this.patientIdentifierNumber, client_id_type: this.patientIdentifierType }

      this.api('clinical.hie.api.client_search_by_id', args).then(result => {
        if (result.message.url != null) {
          if (this.quick_registration == 1) {
            const patientNumber = result.message.url.split('/').slice(-1)[0]
            this.$emit('sendRegisteredPatient', patientNumber)
            // this.msgprint({
            // title: ("Notification"),
            // indicator: "yellow",
            // message: ("Patient with this ID is already registered in the system. Use 'Select existing patient to insert to queue' "),
            // });
          } else {
            const id = result.message.url;
            this.$router.push({ name: 'patient-view-actions', params: { id } })
           
          }
        }
        if (result.message && result.message._client_payload.clientExists) {
          context.registerNew = false
          context.searchResults = result.message._client_payload.client
          this.show_alert('Success, Found user ', 10)
        } else {
          context.registerNew = true
          this.show_alert('User Not found, Please register', 10)
        }

        context.isLoading = false
        context.checkingComplete = true
        this.patientIdentifierNumber = null
      })
    },
    cancel(data) {
      this.checkingComplete = false
      this.searchResults = null
    },

  },
}
</script>

<style></style>
