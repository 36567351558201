<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <nav-title></nav-title>

      <search-bar v-if="false" />
      <!-- Bookmarks Container -->
      <bookmarks v-if="false" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale v-if="false" />
      <!-- <traverse-queue class="mx-1"></traverse-queue> -->
      <!-- <QuickRegisterPatient></QuickRegisterPatient> -->
      <!-- <PopOverSideBar/> -->
      <dark-Toggler
        v-if="false"
        class="d-none d-lg-block"
      />
      <search-bar v-if="false" />
      <cart-dropdown v-if="false" />
      <notification-dropdown v-if="false" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import PopOverSideBar  from '@/views/erp/clinical/sidebar/PopOverSidebar.vue'
import TraverseQueue from '@/views/erp/clinical/top-header/components/traverse-queue/TraverseQueue.vue'
import  NavTitle from '@/views/erp/components/NavTitle.vue'
import QuickRegisterPatient from '@/views/erp/clinical/top-header/components/quick-register/QuickRegisterPatient.vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    NavTitle,
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    TraverseQueue,
    QuickRegisterPatient,
    PopOverSideBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted(){
    if (!window.roles == null && this.getUserSession().user) {
      this.getRoles()
    }
  }
}
</script>
