<!-- ApiSearch.vue -->
<template>
  <div>
    <v-select
      v-model="selectedItem"
      :options="searchResults"
      label="name"
      placeholder="Search..."
      @search="searchApi"
      @input="emitSelectedItem"
    />

    <div v-if="selectedItem">
      <p>Selected Item: {{ selectedItem.name }}</p>
      <!-- Display other information about the selected item as needed -->
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      selectedItem: null,
      searchResults: [],
    }
  },
  methods: {
    searchApi(searchTerm) {
      useJwt.api('frontend.api.moh.cr.search_cr', {
        query: searchTerm,
      })
        .then(result => {
          this.searchResults = result.data.message
        })
    },
    emitSelectedItem() {
      this.$emit('patientCallback', this.selectedItem)
    },
  },
}
</script>

  <style scoped>
  /* Add your component-specific styles here */
  </style>
