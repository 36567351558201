<template>
    <b-row align-v="center">
        <h5  @click="setRoute('HOme')" class="m-1"> <b>{{ (context || "").toUpperCase()}}</b> </h5>
    </b-row>
</template>
<script>

export default {
    name: "Nav Title",
    methods: {
       
    }, computed: {
        context() {
            return this.$store.getters['context/getCurrentRoute'] || ""
        },
    
    },
}
</script>