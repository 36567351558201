<template>
    <div class="d-sm-none">
        <b-button v-if="type == 6" size="sm" variant="outline-secondary" v-b-toggle.sidebar-1678 class="floating-button">
            <i class="fa-solid fa-users"></i>
        </b-button>

        <b-button v-if="type == 1" size="sm" variant="outline-secondary" v-b-toggle.sidebar-1678>
            <b-button size="sm" variant="outline-secondary" v-b-toggle.sidebar-1678 class="floating-button">
                <i class="fa-solid fa-users"></i>
            </b-button>
        </b-button>
        <b-sidebar id="sidebar-1678" left shadow   backdrop>
            <SideBar></SideBar>
        </b-sidebar>
    </div>
</template>
<script>
import SideBar from "./Sidebar.vue"
export default {
    name: "PopOverSideBar",
    props: {
        type: {
            default: 0
        }
        
    },
    components: {
        SideBar,
    },
}
</script>
<style scoped>
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    padding: 15px;
    font-size: 14px;
    z-index: 5000;
    margin-right: 6px;
}
</style>