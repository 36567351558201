<template>
  <div>
    <b-button
      v-if="selectedServicePoint.point_name"
      v-b-tooltip.hover
      :pressed="false"
      style=" !important;"
      size="sm"
      title="Patient Queue"
      variant="secondary"
      @click=" isVisible=true ;$bvModal.show('traversemodal'); getPatientsAwaiting()"
    >
      <feather-icon
        icon="UsersIcon"
        size="14"
      />
    </b-button>

    <div>
      <b-modal
        v-if="isVisible"
        id="traversemodal"
        size="md"
        hide-footer
      >
        <template #modal-title>
          Filter patients based on status.
          <p v-if="selectedQueueDate">
            For Date: <b>{{ selectedQueueDate }}</b>
          </p>
        </template>
        <div>
          <div class="mt-3">

            <b-table
              small
              bordered
            stacked="md"
              :items="tableData"
              :fields="fields"
            >
              <template v-slot:cell(Id)="data">
                <!-- Actions Column -->
                {{ data.index +1 }}
              </template>
              <template v-slot:cell(actions)="data">
                <!-- Actions Column -->
                <b-button
                  size="sm"
                  variant="dark"
                  @click="getList(data.item.title)"
                >
                  View
                </b-button>
              </template>
              <template v-slot:cell(title)="data">
                <!-- Title Column -->
                {{ data.item.title }}
              </template>
              <template v-slot:cell(count)="data">
                <!-- Count Column -->
                {{ data.item.count }}
              </template>
            </b-table>
            <!--b-button-group v-if="selectedServicePoint.point_name" size="sm"-->
            <!-- <b-button pill v-on:click="getList('Awaiting Visit Payment')" variant="dark">Awaiting Visit
                            Payment({{ number_awaiting_payment }})</b-button>
                        <b-button pill style="margin-top:5px;" v-on:click="getList('Awaiting Vitals')"
                            variant="danger">Awaiting Vitals ({{ number_awaiting_vitals }})</b-button>
                        <b-button pill style="margin-top:5px;" v-on:click="getList('Awaiting Consultation')"
                            variant="warning">Awaiting Consultation ({{ number_awaiting_consultation }})</b-button>
                        <b-button pill style="margin-top:5px;" v-on:click="getList('Awaiting Investigation')"
                            variant="info">Awaiting Investigation ({{ number_awaiting_investigation }})</b-button>
                        <b-button pill style="background-color:#d7e4d5;color:black;margin-top:5px;"
                            v-on:click="getList('Investigation Completed')">Investigation Completed
                            ({{ number_investigation_completed }})</b-button>
                        <b-button pill style="margin-top:5px;" v-on:click="getList('Visit Completed')"
                            variant="success">Visit Completed ({{ number_visit_completed }})</b-button>
                        <b-button pill style="margin-top:5px;" v-on:click="getList('Awaiting Specialist Review')"
                            variant="light">Awaiting Specialist Review ({{ number_awaiting_specialist_review }})</b-button>
                        <b-button pill style="background-color:#2590ef;margin-top:5px;color:white"
                            v-on:click="getList('Scheduled For Admission')">Scheduled For Admission
                            ({{ number_scheduled_for_admission }})</b-button> -->
            <!--/b-button-group-->

            <b-button-group
              v-if="selectedServicePoint.point_name == 'xx'"
              size="sm"
            >
              <b-button
                pill
                variant="success"
                @click="getList('Admitted')"
              >Admitted
                ({{ noAdmitted }})</b-button>
              <b-button
                pill
                variant="light"
                @click="getList('Admission Scheduled')"
              > Scheduled For
                Admission ({{ noAdmissionScheduled }})</b-button>
              <b-button
                pill
                variant="warning"
                @click="getList('Discharge Scheduled')"
              >Discharge Scheduled
                ({{ noDischargeScheduled }})</b-button>

            </b-button-group>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { getPatientsAwaiting } from './service'

export default {
  name: 'TraverseQueue',
  props: {
    details: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      number_awaiting_payment: Number,
      number_awaiting_vitals: Number,
      number_awaiting_consultation: Number,
      number_awaiting_investigation: Number,
      number_investigation_completed: Number,
      number_visit_completed: Number,
      number_awaiting_specialist_review: Number,
      number_scheduled_for_admission: Number,
      noAdmissionScheduled: Number,
      noAdmitted: Number,
      noDischargeScheduled: Number,
      fields: [{ key: 'Id', label: '' }, 'title', 'count', 'actions'],

    }
  },
  computed: {
    tableData() {
      return [
        { title: 'Awaiting Visit Payment', count: this.number_awaiting_payment },
        { title: 'Awaiting Vitals', count: this.number_awaiting_vitals },
        { title: 'Awaiting Consultation', count: this.number_awaiting_consultation },
        { title: 'Awaiting Investigation', count: this.number_awaiting_investigation },
        { title: 'Investigation Completed', count: this.number_investigation_completed },
        { title: 'Visit Completed', count: this.number_visit_completed },
        { title: 'Awaiting Specialist Review', count: this.number_awaiting_specialist_review },
        { title: 'Scheduled For Admission', count: this.number_scheduled_for_admission },
      ]
    },
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint'] || (this.servicePoints.length && this.servicePoints[0])
    },
    myLocation() {
      return this.$store.getters['servicePoint/getMyLocation']
    },
    servicePoints() {
      return this.$store.getters['servicePoint/getServicePoints']
    },
    selectedQueueDate() {
      return this.$store.getters['servicePoint/getQueueDate']
    },
  },
  watch: {
    servicePoints(service_points) {
      this.$store.dispatch('servicePoint/setSelectedServicePoint', service_points[0])
    },
  },
  mounted() {
  },
  methods: {
    getPatientsAwaiting() {
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Awaiting Visit Payment', this.selectedQueueDate).then(
        res => {
          this.number_awaiting_payment = res
        },
      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Awaiting Vitals', this.selectedQueueDate).then(
        res => {
          this.number_awaiting_vitals = res
        },
      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Awaiting Consultation', this.selectedQueueDate).then(
        res => {
          this.number_awaiting_consultation = res
        },

      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Awaiting Investigation', this.selectedQueueDate).then(
        res => {
          this.number_awaiting_investigation = res
        },

      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Investigation Completed', this.selectedQueueDate).then(
        res => {
          this.number_investigation_completed = res
        },

      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Visit Completed', this.selectedQueueDate).then(
        res => {
          this.number_visit_completed = res
        },

      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Awaiting Specialist Review', this.selectedQueueDate).then(
        res => {
          this.number_awaiting_specialist_review = res
        },

      )
      getPatientsAwaiting(this.selectedServiceUnit.service_unit, 'Scheduled For Admission', this.selectedQueueDate).then(
        res => {
          this.number_scheduled_for_admission = res
        },

      )
      // getInpatientsStatusCount(this.selectedServiceUnit.service_unit).then(
      //     (res)=>{
      //         this.noAdmitted = res.Admitted
      //         this.noAdmissionScheduled = res.AdmissionScheduled
      //         this.noDischargeScheduled = res.DischargeScheduled
      //     }

      // )
    },
    getList(status) {
      this.getPatientList(this.selectedServiceUnit.service_unit, status, 'traverse', this.selectedQueueDate)
      this.isVisible = false;
      this.show_alert("Queue Loaded")
    },
    getPatientList(serviceUnit, search, traverse, passedDate) {
      if (serviceUnit) {
        const payload = {
          serviceUnit, search, traverse, passedDate, work: 'components/patient/traversequeue',
        }
        this.$store.dispatch('patient/fetchPatients', payload)
      }
    },
  },

}
</script>

<style scoped>.focus {}</style>
