<template>
  <div>
    <b-row class="mt-1">
      <b-col cols="12">
        <b-list-group class="scroll-list">
          <patient-list-item
            v-for="(patient, index) in patientList"
            :key="patient.name"
            :patient="patient"
            :index="index"
            :active-patient="activePatient"
            :full-screen-work-area="fullScreenWorkArea"
            :status="patient.status"
            :is-active="isActive"
            :current-page="currentPage"
            @patient-selected="patientClick"
          >{{ activePatient.name }} {{ patient.name }}</patient-list-item>
          <div
            v-if="patientList.length < 1"
            id="contain"
          >
            <img
              v-if="false"
              src="https://portal.mtrh.go.ke/files/findnoresult2.png"
            >
            <b><h style="font-weight: 600;">Apologies, but we are unable to locate the patient(s) you are searching for</h></b>
          </div>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PatientListItem from './PatientListItem.vue'

export default {
  name: 'PatientList',
  components: {
    PatientListItem,
  },
  props: {
    patientList: {
      type: Array,
      default: [],

    },

    fullScreenWorkArea: {
      type: Boolean,
      default: false,
    },
    rows: Number,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      activePatient: {},
      isActive: null,
    }
  },
  computed: {
    position: () => Math.floor(Math.random() * 1000),
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    currentPage(page) {
      this.$emit('current-page', page)
    },
    patientList(newList) {
      if (newList && newList.length > 0) {
        if (this.findSelectedPatientInNewList(newList) == false) {
          this.patientClick(this.patientList[0])
        }
      }
    },
  },
  methods: {
    patientClick(patient) {
      this.$emit('selected-patient', patient)
      this.activePatient = patient
      this.$store.dispatch('patient/setSelectedPatient', patient)
    },
    findSelectedPatientInNewList(newList) {
      const currentPatient = this.selectedPatient.patient
      let isPart = false
      newList.forEach(item => {
        if (item.patient == currentPatient) {
          isPart = true
        }
      })

      return isPart
    },
  },
}
</script>
<style scoped>

.pagination-spacing {
  margin-top: 6%;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  /* font-size: .75rem; */
  line-height: 1.5;
}

.scroll-list{
/* height: 72vh; */
overflow-y: scroll;
overflow-x: hidden;
scrollbar-width:thin;
background: white;
height: 70vh !important;
}
.list-group {
    border-radius: 5px;
}
#contain {
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
