<template>
  <b-container>

    <b-row
      v-if="!formName"
      class="render-formx mr-0"
    >

      <b-col
        ref="doctype"
        :cols="6"
        class="ref-field-input mr-0"
      />
      <b-col
        :cols="6"
        class="ref-field-input mr-0"
      >
        <b-button
          v-for="(value, index) in values"
          :key="index"
          variant="primary"
          @click="getForm(value)"
        >
          {{ value }}
        </b-button>
      </b-col>

    </b-row>
    <b-row>
      <form-render-view
        :current-form-name="selectedDoctype"
        :selected-doctype="referencDoctype"
        :pre-populate="prePopulate"
        :selected-doctype-reference="referencDoctypeItem"
        @callback="callback"
        @encounterCallback="encounterCallback"
      />
    </b-row>
  </b-container>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import FormRenderView from './Frm.vue'

const KEY = 'savedForms'

export default {
  name: 'FillForm',
  components: {
    FormRenderView,
  },
  props: {
    formName: {
      type: String,
      required: false,
      default: 'Registration Form',
    },
    prePopulate: { type: Object, default: null },
    referencDoctype: { type: String, default: 'Patient' },
    referencDoctypeItem: { type: String, default: '' },
    callbackUrl: { type: Object, required: false, default: null },

  },
  data() {
    return {
      selectedDoctype: null,
      configuration: null,
      values: [],
    }
  },
  watch: {
    selectedDoctype(val) {
      this.getForm(val)
      // this.checkAndAddValue(val)
    },
  },
  created() {
    if (this.formName) {
      this.selectedDoctype = this.formName
    }
  },

  mounted() {
    // this.makeSelectDoctypeControl();
    console.log('Fill form loaded')
  },

  methods: {
    callback(value) {
      this.$emit('callback', value)
    },
    encounterCallback(patient) {
      this.$emit('encounterCallback', patient)
    },
    makeSelectDoctypeControl() {
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Reference'),
          fieldtype: 'Link',
          fieldname: 'reference',
          options: 'Form Design',
          placeholder: ('Search Reference'),
          onchange() {
            if (this.value) {
              me.selectedDoctype = this.value
            }
          },
        },
        parent: this.$refs.doctype,
        render_input: true,
      })

      customer_field.toggle_label(false)
      $('#modal-body')
        .find('.input-max-width')
        .removeClass('input-max-width')
    },
    getForm(name) {
      useJwt.api('clinical.api.forms.form_builder.get_form_configuration', { name }).then(config => {
        config = config.data.message

        const formStringConfig = config.formdata
        const configObject = JSON.parse(formStringConfig)
        this.configuration = formStringConfig
        this.formName = config.name
        this.formData = configObject
        this.originalConfig = configObject
      })
    },
    goToRoute(doctype, reference) {
      this.$router.push({ name: 'viewer', params: { doctype, reference } })
    },
  },
  checkAndAddValue(value) {
    // Check if the key exists
    if (!localStorage.getItem(KEY)) {
      // If it doesn't exist, create an array with the value and save it in local storage
      const valuesArray = [value]
      localStorage.setItem(KEY, JSON.stringify(valuesArray))
    } else {
      // If it does exist, check if the value is in the array
      const valuesArray = JSON.parse(localStorage.getItem(KEY))
      if (!valuesArray.includes(value)) {
        // If the value is not in the array, add it
        valuesArray.push(value)
        localStorage.setItem(KEY, JSON.stringify(valuesArray))
      } else {
        // If the value is in the array, do nothing or handle as necessary
        console.log('Value already exists in the array.')
      }
    }
  },
  getAllValues() {
    // Check if the key exists
    if (localStorage.getItem(KEY)) {
      // If it does exist, retrieve and return all the values
      return JSON.parse(localStorage.getItem(KEY))
    }
    // If it does not exist, return an empty array or handle as necessary
    console.log('Key does not exist in local storage.')
    return []
  },
}
</script>

<style scoped>
.render-formx {
  padding-top: 10px;
  padding-bottom: 0px;
  width: 100% !important;
}
</style>
