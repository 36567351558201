const THEME_MIXIN = {
  data() {
    return {
      variants: [
        'primary',
        'secondary',
        'danger',
        'warning',
        'light',
        'success',
        'info',
        'dark',
      ],
      variant: {
        primary: 'primary',
        secondary: 'secondary',
        danger: 'danger',
        warning: 'warning',
        light: 'light',
        success: 'success',
        info: 'info',
        dark: 'dark',
        purple: 'purple',
      },
      tabProp: {
        Timeline: {
          icon: 'fa-line-chart',
          color: 'green',
        },
        Procedures: {
          icon: 'fa-file-text-o',
          color: '#a4a433',
        },
        'Drug Administration': {
          icon: 'fa-medkit',
          color: 'royalblue',
        },
        'Vital Signs': {
          icon: 'fa-heartbeat',
          color: 'rgb(147, 4, 4)',
        },
        Kardex: {
          icon: 'fa-pencil-square-o',
          color: '#0e9595',
        },
        'Doctors Notes': {
          icon: 'fa-user-md',
          color: '#a4a433',
        },
        Orders: {
          icon: 'fa-file-text-o',
          color: '#28a745',
        },
        Prescriptions: {
          icon: 'fa-medkit',
          color: 'royalblue',
        },
        Accounts: {
          icon: 'fa-credit-card-alt',
          color: 'darkorange',
        },
        Forms: {
          icon: 'fa-folder-open',
          color: 'rgb(52, 113, 166)',
        },
        Labs: {
          icon: 'fa-flask',
          color: 'darkblue',
        },
      },
    }
  },
}

export { THEME_MIXIN }
